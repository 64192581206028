<template>
    <section>
        <Navigator />
        <main>
            <h1>Aviso de <span>Privacidad Integral</span></h1>

            <div class="wrapper">
                <p>
                    <span>{{ name }}</span>, con domicilio en {{ direction }} y
                    portal de internet <br>
                    <a href="/" target="_blank">{{ link }}</a>,
                    es
                    el responsable del uso y
                    protección de sus datos personales, y al respecto le informamos lo siguiente:
                </p>

                <h2>¿Para qué fines utilizaremos sus datos personales?</h2>

                <p>
                    Los datos personales que recabamos de usted, los utilizaremos para las siguientes finalidades que
                    son
                    necesarias para el servicio que solicita:
                </p>

                <ul>
                    <li>
                        <p>Respuesta a mensajes del formulario de contacto</p>
                    </li>
                    <li>
                        <p>Prestación de cualquier servicio solicitado</p>
                    </li>
                </ul>

                <h2>
                    ¿Qué datos personales utilizaremos para estos fines?
                </h2>
                <p>
                    Para llevar a cabo las finalidades descritas en el
                    presente aviso de privacidad, utilizaremos los siguientes datos personales:
                </p>

                <ul>
                    <li>
                        <p> Datos de identificación y contacto</p>
                    </li>
                </ul>
                <h2>
                    ¿Cómo puede acceder, rectificar o cancelar sus datos personales, u oponerse a su uso o ejercer la
                    revocación de consentimiento?
                </h2>

                <p>
                    Usted tiene derecho a conocer qué datos personales tenemos de usted, para
                    qué los utilizamos y las condiciones del uso que les damos (Acceso). Asimismo, es su derecho
                    solicitar
                    la corrección de su información personal en caso de que esté desactualizada, sea inexacta o
                    incompleta
                    (Rectificación); que la eliminemos de nuestros registros o bases de datos cuando considere que la
                    misma
                    no está siendo utilizada adecuadamente (Cancelación); así como oponerse al uso de sus datos
                    personales
                    para fines específicos (Oposición). <br> <br>
                    Estos derechos se conocen como <span>derechos ARCO.</span>
                </p>

                <p>
                    Para el ejercicio de cualquiera de los <span>derechos ARCO,</span> debe enviar una petición vía
                    correo
                    electrónico a
                    <a :href="`mailto:${mailto}`">{{ mailto }}</a> y deberá contener:
                </p>

                <ul>
                    <li>
                        <p>1. Nombre completo del titular</p>
                    </li>
                    <li>
                        <p>2. Domicilio</p>
                    </li>
                    <li>
                        <p>3. Teléfono</p>
                    </li>
                    <li>
                        <p>4. Correo electrónico usado en este sitio web</p>
                    </li>
                    <li>
                        <p>5. Copia de una identificación oficial adjunta</p>
                    </li>
                    <li>
                        <p>6. Asunto <span>«Derechos ARCO»</span></p>
                    </li>
                </ul>

                <p>
                    Descripción del objeto del escrito, los cuales pueden ser de manera enunciativa más no limitativa
                    los
                    siguientes: Revocación del consentimiento para tratar sus datos personales; y/o Notificación del uso
                    indebido del tratamiento de sus datos personales; y/o Ejercitar sus <span>Derechos ARCO,</span> con
                    una
                    descripción
                    clara y precisa de los datos a Acceder, Rectificar, Cancelar o bien, Oponerse. En caso de
                    Rectificación
                    de datos personales, deberá indicar la modificación exacta y anexar la documentación soporte; es
                    importante en caso de revocación del consentimiento, que tenga en cuenta que no en todos los casos
                    podremos atender su solicitud o concluir el uso de forma inmediata, ya que es posible que por alguna
                    obligación legal requiramos seguir tratando sus datos personales. Asimismo, usted deberá considerar
                    que
                    para ciertos fines, la revocación de su consentimiento implicará que no le podamos seguir prestando
                    el
                    servicio que nos solicitó, o la conclusión de su relación con nosotros.
                </p>

                <ul>
                    <li>
                        <p>¿En cuántos días le daremos respuesta a su solicitud? <br><span>20 días</span>.</p>
                    </li>
                    <li>
                        <p>¿Por qué medio le comunicaremos la respuesta a su solicitud? <br><a
                                :href="`mailto:${mailto}`">{{
                                    mailto }}</a></p>
                    </li>
                </ul>

                <p>
                    El uso de tecnologías de rastreo en nuestro portal de internet Le informamos que en nuestra página
                    de
                    internet utilizamos cookies, web beacons u otras tecnologías, a través de las cuales es posible
                    monitorear su comportamiento como usuario de internet, así como brindarle un mejor servicio y
                    experiencia al navegar en nuestra página. Los datos personales que obtenemos de estas tecnologías de
                    rastreo son los siguientes: Identificadores, nombre de usuario y contraseñas de sesión. Estas
                    cookies,
                    web beacons y otras tecnologías pueden ser deshabilitadas. Para conocer cómo hacerlo, consulte el
                    menú
                    de ayuda de su navegador. Tenga en cuenta que, en caso de desactivar las cookies, es posible que no
                    pueda acceder a ciertas funciones personalizadas en nuestro sitio web.
                </p>

                <h2>
                    ¿Cómo puede conocer los cambios en este aviso de privacidad?
                </h2>
                <p>
                    El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de
                    nuevos requerimientos legales; de nuestras
                    propias necesidades por los productos o servicios que ofrecemos; de nuestras prácticas de
                    privacidad; de
                    cambios en nuestro modelo de negocio, o por otras causas. Nos comprometemos a mantener actualizado
                    este
                    aviso de privacidad sobre los cambios que pueda sufrir y siempre podrá consultar las actualizaciones
                    que
                    existan en el sitio web <a href="/" target="_blank">{{ link }}</a>.
                </p>
            </div>
        </main>
    </section>
</template>
<script>
import Navigator from '../components/Navigator.vue';
export default {
    data() {
        return {
            name: "PlayTennis",
            link: "www.playtennis.mx",
            direction: "Blvd. Juan Navarrete Colonia Montebello Hermosillo, Sonora C.P. 83210",
            mailto: "contacto@playtennis.mx",
        }
    },
    components: {
        Navigator,
    }
}
</script>
<style scoped>
*,
*::before,
*::after {
    box-sizing: border-box;
}

section {
    padding: 4.1062801932367154VW 0 0;
    background-size: cover;

    background-image: url("../assets/aviso_mov.png");
    background-image: url("../assets/aviso_mov.webp"); 
}

main {
    padding: 16.908212560386474VW 11.352657004830919VW 162.80193236714976VW 14.009661835748794VW;
}

h1 {
    color: #000;
    font-family: "Monument-bold";
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;

    font-size: 7.246376811594203VW;
    line-height: 90.5%;
    letter-spacing: 0.21739130434782608VW;
    margin: 7.246376811594203VW 0;
}

h1 span {
    color: #55CD00;
    font-size: 9.66183574879227VW;
    font-weight: 800;
    letter-spacing: 0.2898550724637681VW;
}

p,
h2 {
    color: #7B7B7B;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 600;

    font-size: 4.830917874396135VW;
    line-height: 115.5%;
    margin: 4.830917874396135VW 0;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

ul p {
    margin: 0;
}

@media (min-width: 768px) {
    section {
        padding: 3.90625VW 0 0;
        background-image: url("../assets/aviso_pc.png");
        background-image: url("../assets/aviso_pc.webp");
    }

    main {
        padding: 5.572916666666667VW 8.489583333333334VW 5.3125VW 35.41666666666667VW;
    }

    h1 {
        font-size: 3.28125VW;
        line-height: 90.5%;
        letter-spacing: 0.0984375VW;
        margin: 0 0 6.09375VW;
    }

    h1 span {
        display: block;
        font-size: 3.802083333333333VW;
        font-weight: 800;
        letter-spacing: 0.05677083333333334VW;
    }

    p,
    h2 {
        font-size: 0.9375VW;
        line-height: 115.5%;
        margin: 0.9375VW 0;
    }
}
</style>